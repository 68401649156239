@import url('https://fonts.googleapis.com/css2?family=Martel&family=Roboto+Flex:opsz@8..144&family=Roboto:wght@300&display=swap');

body {
    margin: 0 auto;
    padding: 0;
    
}

* {
    box-sizing: border-box;
}

h1 {
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    text-align: center;
    color: var(--forest);
    font-family: var(--ff-accent);
    background: var(--backgroundColor);
    
}

.containerDivHero {
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    background: var(--backgroundColor);
}

.topDiv {
    width: 100vw;
    margin: 0 auto;
    background: var(--backgroundColor);
}

.imgDiv {
    width: 100vw;
    height: 17em;
    background-color: var(--sheer-sienna);
    background-blend-mode: screen;
    background-size: 300%;
    background-position: 35% 35%;
    color: var(--backgroundColor);
    background-repeat: no-repeat;
    margin: 0 auto;
}

.imgDiv-p {
    position: relative;
    top: 1.5em;
    left: 30.5em;
    width: 9em;
    font-size: .5rem;
    letter-spacing: .1rem;
    font-weight: 700;
    text-shadow: var(--green-shadow);
    color: var(--backgroundColor);
}

.bottomDiv {
    width: 100vw;
    height: 3em;
    margin: 0 auto;
    background: var(--vanilla);
}

@media (min-width: 600px) {

    .imgDiv {
        width: 100vw;
        height: 22em;
        background-blend-mode: screen;
        background-size: 200%;
        background-position: 35% 33%;
        background-repeat: no-repeat;
    }

    .imgDiv-p {
        position: relative;
        top: 1em;
        left: 40em;
        width: 9em;
        font-size: .8rem;
        
    }

    h1 {
        letter-spacing: 0.3em;
        font-size: 2.7rem;
        margin-top: .2em;

    }
}

@media (min-width: 1025px) {

    .imgDiv {
        width: 100vw;
        height: 50em;
        background-blend-mode: screen;
        background-position: 55% 35%; 
        background-size: cover;
    }

    .imgDiv-p {
        position: relative;
        top: 12em;
        left: 55em;
        width: 25em;
        font-size: 1rem;
    }

}