@import url('https://fonts.googleapis.com/css2?family=Martel&family=Roboto+Flex:opsz@8..144&family=Roboto:wght@300&display=swap');

body {
    margin: 0 auto;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.youTubeContainer {
    margin: 0 auto 1em auto;
}

.youTubeInnerContainer {
    margin: 0 auto;
    width: 100vw;
    height: 20em;

}

.youtube-div--p {
    width: 100vw;
    background: var(--feather-grey);
    padding: 1em;
    letter-spacing: .05rem;
    text-align: center;
}

.youtubeiframe {
    height: 20em;
    width: 100vw;
    padding-top: .7em;
    padding-bottom: .7em;
}

@media (min-width: 600px) {

    .youTubeContainer {
        margin: 0 auto;
    }

    .youTubeInnerContainer {
        width: 70vw;
        height: 30em;
    }

    .youtubeiframe {
        width: 70vw;
        height: 30em;
        z-index: 3;
    }

    
}
@media (min-width: 1025px) {

    .youTubeInnerContainer {
        width: 50vw;
        height: 30em;
    }

    .youtubeiframe {
        width: 50vw;
        height: 30em;
        z-index: 6;
    }
}