@import url('https://fonts.googleapis.com/css2?family=Martel&family=Roboto+Flex:opsz@8..144&family=Roboto:wght@300&display=swap');

body {
    margin: 0 auto;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.scheduleDiv {
    margin: 0 auto .5em auto;
    text-align: center;
    background-color: var(--vanilla);

}

.containerDivSched {
    border-radius: 4px; 
    height: auto;
    width: 20em;
    margin: 0 auto;
    padding: .7em;
    letter-spacing: .1rem;
    text-align: left;
    background: var(--feather-grey);
    font-size: auto;
} 

.gigContent {
    background: var(--feather-grey);
}


@media (min-width: 600px) {

    .scheduleDiv {
        margin: 0 auto;
    }


    .containerDivSched {
        margin: 0 auto;
        width: auto;
        height: auto;
        font-size: auto;
    }

    .bookBtnSched {
        margin: 1em auto 1em auto;
        width: 12em;
        z-index: 3;
    }

}
@media (min-width: 1025px) {

    .scheduleDiv {
        margin: 0 auto;
    }

    .containerDivSched {
        font-size: 1.5rem;
    }

    .bookBtnSched {
        z-index: 1000;
        pointer-events: inherit;
    }
}