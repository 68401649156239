@import url('https://fonts.googleapis.com/css2?family=Martel&family=Roboto+Flex:opsz@8..144&family=Roboto:wght@300&display=swap');


body {
    margin: 0 auto;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.bookBtnContainer {
    margin: 0 auto .5em auto;
    text-align: center;
}

.bookBtn {
    background-image: radial-gradient(
        circle at 50% 50%, 
        var(--dark-forest), 
        var(--fern1), 
        var(--fern2),
        var(--fern3),
        var(--fern4),
        var(--fern5),
        var(--fern6),
        var(--forest)
    );

    background-size: 200% auto; 
    border: 1px solid var(--dark-teal);
    border-radius: 4px; 
    font-weight: 900;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2em;
    color: var(--backgroundColor);
    width: 90vw;
    height: 4em;
    margin: 0 auto;
    cursor: pointer;
    transition: all .5s; 
    animation: textclip 1.5s linear infinite alternate;
    animation-timing-function: ease-out;
}

.bookBtn:hover,
.bookBtn:focus {
    transform: scale(1.1);
    cursor: pointer;
}   


@keyframes textclip {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 200% 100%;
  }
} 

@media (min-width: 600px) {

    .bookBtnContainer {
        margin: 0 auto 1.5em auto;
        text-align: center;
    }

    .bookBtnSched {
        font-size: 15px;
    }

    .bookBtn{
        font-size: 22px;
    }
    
}
@media (min-width: 1025px) {
    .bookBtnSched {
        font-size: 20px;
    }

    .bookBtn{
        font-size: 32px;
    }
}