@import url('https://fonts.googleapis.com/css2?family=Martel&family=Roboto+Flex:opsz@8..144&family=Roboto:wght@300&display=swap');

body {
    margin: 0 auto;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.contactFormParent {
    position: absolute;
    color: var(--textdetailColor);
    top: 5em;
    left: 0;
    padding: .3em .3em .3em .9em;
    display: none;
    background: var(--backgroundColor);
    border-left: 15px solid var(--forest);
    border-right: 15px solid var(--forest);
    border-top: 2rem solid var(--forest);
    border-bottom: 9.5rem solid var(--forest);
    border-radius: 7px;
    z-index: 3;
    height: auto;
    width: 100%;
    letter-spacing: .07rem;
    font-family: var(--ff-main1);
    text-align: left;
}

h2 {
    font-size: 1rem;
}

textarea {
    color: var(--textdetailColor);
    width: 95%;
    font-family: inherit;
    padding: .4em;
    margin-bottom: .7em;
    height: 7em;
    border: 1px solid var(--textdetailColor);
    border-radius: 4px; 
}

input {
    width: 95%;
    color: var(--textdetailColor);
    font-family: inherit;
    padding: .4em;
    margin-bottom: .7em;
    border: 1px solid var(--textdetailColor);
    border-radius: 4px; 
}

.modalBtnClose {
    color: var(--dark-blue);
    float: right;
    letter-spacing: .2rem;
    text-transform: uppercase;
    z-index: 5;
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    font-size: 2.7rem;
    transform: rotate(45deg);
    font-weight: 700;
}

.modalBtnSubmit {
    position: relative;
    background: var(--dark-blue);
    color: var(--backgroundColor);
    letter-spacing: .2rem;
    text-transform: uppercase;
    z-index: 5;
    cursor: pointer;
    border: none;
    text-align: center;
    padding: .7em;
    margin-bottom: .7em;
    margin-left: 30%;
    width: 35%;
    border-radius: 4px; 
}

.modalBtnSubmit:hover {
    background: var(--brown);
}

@media (min-width: 600px) {
   
}
@media (min-width: 1025px) {}