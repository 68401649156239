@import url('https://fonts.googleapis.com/css2?family=Martel&family=Roboto+Flex:opsz@8..144&family=Roboto:wght@300&display=swap');

body {
    margin: 0 auto;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.instagramContainerDiv {  
    width: 100vw;
    font-family: var(--ff-main1);
    text-align: center;
    justify-content: center;
   
} 

.instagram-div {
    width: 100vw;
    margin: 0 auto;
    background: var(--feather-grey);
}

.instagram-p {
    width: 100vw;
    background: var(--feather-grey);
    padding: 1em;
    letter-spacing: .05rem;
}

.instagramInnerContainerDiv {
    width: 100vw;
    max-height: 100vh;
}

.snapwidget-widget {
    border: none;
    z-index: 10;
    width: 100vw;
    padding: .5em;
    max-height: 100vh;
    pointer-events: inherit;
}

@media (min-width: 600px) {

    .instagramContainerDiv {  
        margin: 0 auto;
    } 

    .instagram-p {
        margin: 0 auto;
    }

    .instagramInnerContainerDiv {
        width: 85vw; 
        margin: 0 auto;
    }

    .snapwidget-widget {
        width: 85vw;
    }
}
@media (min-width: 1025px) {}